import React, { useEffect, useRef, useState } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Pattern, SITE_KEY_TEST } from "../Utils/constant";
import OnboardingService from "../services/onboarding.service";
import { useParams } from 'react-router-dom';
import Header from "../components/Header";
import './styles.css';

const Portal = ({ isVerified, error }) => {
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");
  const [response, setResponse] = React.useState({
    isResponded: false,
    success: false,
    message: ''
  });
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const { company, code } = useParams();
  const sectionCss = isVerified ? "" : "second-section";
  const inputCss = "block w-full py-3 pl-4 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600";

  const [defaultCompany, setDefaultCompany] = React.useState("");
  const [defaultCode, setDefaultCode] = React.useState("");
  const [inputCompanyProp, setInputCompanyProp] = React.useState({
    disable: false,
    css: inputCss
  });
  const [inputCodeProp, setInputCodeProp] = React.useState({
    disable: false,
    css: inputCss
  });

  const onSubmit = (event) => {
    setSubmitted(true);
    event.preventDefault();

    const firstname = event.target.firstname.value;
    const lastname = event.target.lastname.value;
    const jobTitle = event.target.title.value;
    const email = event.target.email.value;
    const urlCompanyName = event.target.urlCompanyName.value;
    const accessCode = event.target.accessCode.value;

    if (
      validate("firstname", firstname) &&
      validate("lastname", lastname) &&
      validate("urlCompanyName", urlCompanyName) &&
      validate("accessCode", accessCode) &&
      validate("email", email) &&
      validate("captcha", token) &&
      validate("title", jobTitle)

    ) {
      const payload = {
        firstName: firstname,
        lastName: lastname,
        jobTitle,
        email,
        accessCode,
        urlCompanyName
      }

      setIsBtnLoading(true);
      OnboardingService.initiateOnboarding(payload).then(res => {
        setResponse({
          ...response,
          isResponded: true,
          success: true,
          message: 'A confirmation email has been sent to the email address provided.'
        })
        setIsBtnLoading(false);
      }).catch(err => {
        let message = 'The information provided is not currently eligible for an activation key.';
        if (err.code === 400) {
          if (err.status === "BAD_REQUEST") {
            message = 'Validation failed on the request body. Please try again.';
          } else if (err.status === "LIMIT_REACHED") {
            message = 'The enterprise has exhausted its license pool.';
          } else if (err.status === "MEMBER_ALREADY_EXISTS") {
            message = 'Member with this email address already exists.';
          }
        } else if (err.code === 404 && err.status === "NOT_FOUND") {
          message = 'The information provided is not currently eligible for an activation key.';
        }

        setResponse({
          ...response,
          isResponded: true,
          success: false,
          message
        })
        setIsBtnLoading(false);
      });
    }
  };

  const onExpire = () => {
    setToken(null);
  };

  const onError = (err) => {
    console.log(`hCaptcha Error: ${err}`);
    setToken(null);
  };

  const onValueChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (submitted) {
      validate(name, value);
    }
  }

  const validate = (key, value) => {
    let error = ''
    if (key === "firstname") {
      if (!Pattern.nameRegex.test(value)) {
        error = 'First name is invalid.'
      }
    }
    if (key === "lastname") {
      if (!Pattern.nameRegex.test(value)) {
        error = 'Last name is invalid.'
      }
    }
    if (key === "urlCompanyName") {
      if (!value) {
        error = 'URL company name is required.'
      }
    }
    if (key === "accessCode") {
      if (!value) {
        error = 'Access code is required.'
      }
    }
    if (key === "email") {
      if (!Pattern.emailRegex.test(value)) {
        error = 'Email address is invalid.'
      }
    }
    if (key === "title") {
      if (value && !Pattern.nameRegex.test(value)) {
        error = 'Job title is invalid.'
      }
    }
    if (key === "captcha") {
      if (!token) {
        error = 'Captcha is required.'
      }
    }

    setFormErrors({
      ...formErrors,
      [key]: error.length > 0 ? error : null
    })

    if (error.length > 0) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (token) {
      // Token is set, can submit here
      setFormErrors({
        ...formErrors,
        captcha: ''
      })
    }
    if (submitted && !token) {
      setFormErrors({
        ...formErrors,
        captcha: 'Captcha expired'
      })
    }
    // set company value
    setDefaultCompany(company);
    setInputCompanyProp({
      disable: (company !== null && company !== undefined),
      css: (company === null || company === undefined) ? inputCss : inputCss + " input-disabled"
    });
    // set code value
    setDefaultCode(code);
    setInputCodeProp({
      disable: (code !== null && code !== undefined),
      css: (code === null || code === undefined) ? inputCss : inputCss + " input-disabled"
    });

    // set error message
    if (error === "INACTIVE_ENTERPRISE") {
      setErrorMessage("Verification was not successful, enterprise is inactive.");
    } else if (error === "TOKEN_CONFIRMED") {
      setErrorMessage("Verification was not successful, link was already verified. Please check your email for the license key.");
    } else if (error === "INVALID_TOKEN") {
      setErrorMessage("Verification was not successful, invalid link.");
    } else {
      setErrorMessage("Verification was not successful, invalid link. Please try again.");
    }
  }, [token]);

  return (
    <div>
      <Header company={company} code={code}></Header>
      <section className={sectionCss}>
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          {
            isVerified === false &&
            <div className='bg-red-50 rounded transition-all duration-500 mb-4 mt-4 text-left'>
              <div className='p-3'>
                <div className='flex items-start justify-center'>
                  <svg className="flex-shrink-0 w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <p className="ml-3 text-sm font-medium text-red-700">
                    {errorMessage}
                  </p>
                </div>
              </div>
            </div>
          }
          <div className="grid grid-cols-1 mx-auto mt-12 gap-y-4 lg:grid-cols-8 lg:gap-x-20">

            <div className="lg:col-span-3">

              <form onSubmit={onSubmit} className="md:mx-12 lg:mx-auto">
                <div className="space-y-6">

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-5">
                    <div>
                      <label htmlFor="" className="text-sm font-bold text-gray-900">
                        First name*
                      </label>
                      <div className="relative mt-2">
                        <input type="text" name="firstname" id="" placeholder="Jane" defaultValue="" onChange={onValueChange}
                          className="block w-full py-3 pl-4 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                      </div>
                      {
                        formErrors["firstname"] &&
                        (
                          <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                            {
                              formErrors["firstname"]
                            }
                          </ul>
                        )
                      }
                    </div>

                    <div>
                      <label htmlFor="" className="text-sm font-bold text-gray-900">
                        Last name*
                      </label>
                      <div className="relative mt-2">
                        <input type="text" name="lastname" id="" placeholder="Doe" defaultValue="" onChange={onValueChange}
                          className="block w-full py-3 pl-4 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                      </div>
                      {
                        formErrors["lastname"] &&
                        (
                          <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                            {
                              formErrors["lastname"]
                            }
                          </ul>
                        )
                      }
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-5">
                    <div>
                      <label htmlFor="" className="text-sm font-bold text-gray-900">
                        Company Name*
                      </label>
                      <div className="relative mt-2">
                        <input disabled={inputCompanyProp.disable} type="text" name="urlCompanyName" id="" placeholder="company-name" defaultValue={defaultCompany} onChange={onValueChange}
                          className={inputCompanyProp.css} />
                      </div>
                      {
                        formErrors["urlCompanyName"] &&
                        (
                          <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                            {
                              formErrors["urlCompanyName"]
                            }
                          </ul>
                        )
                      }
                    </div>

                    <div>
                      <label htmlFor="" className="text-sm font-bold text-gray-900">
                        Access Code*
                      </label>
                      <div className="relative mt-2">
                        <input disabled={inputCodeProp.disable} type="text" name="accessCode" id="" placeholder="AccessCode123" defaultValue={defaultCode} onChange={onValueChange}
                          className={inputCodeProp.css} />
                      </div>
                      {
                        formErrors["accessCode"] &&
                        (
                          <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                            {
                              formErrors["accessCode"]
                            }
                          </ul>
                        )
                      }
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="text-sm font-bold text-gray-900">
                      Email address*
                    </label>
                    <div className="relative mt-2">
                      <input type="email" name="email" id="" placeholder="jane@company.com" defaultValue="" onChange={onValueChange}
                        className="block w-full py-3 pl-4 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                    </div>
                    {
                      formErrors["email"] &&
                      (
                        <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                          {
                            formErrors["email"]
                          }
                        </ul>
                      )
                    }
                  </div>

                  <div>
                    <label htmlFor="" className="text-sm font-bold text-gray-900">
                      Job title
                    </label>
                    <div className="relative mt-2">
                      <input type="text" name="title" id="" placeholder="Sales Manager" defaultValue="" onChange={onValueChange}
                        className="block w-full py-3 pl-4 pr-4 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                    </div>
                    {
                      formErrors["title"] &&
                      (
                        <ul className="pl-4 mt-2 text-sm font-medium text-red-500 list-disc list-outside">
                          {
                            formErrors["title"]
                          }
                        </ul>
                      )
                    }
                  </div>

                  {
                    response.isResponded === true &&
                    (
                      <React.Fragment>
                        {
                          response.success === true &&
                          (
                            <div className='bg-green-100 rounded transition-all duration-500 mb-4 mt-4'>
                              <div className='p-3'>
                                <div className='flex items-start'>
                                  <svg className='flex-shrink-0 w-5 h-5 text-green-500' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
                                    <path
                                      fillRule='evenodd'
                                      d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                      clipRule='evenodd'
                                    ></path>
                                  </svg>
                                  <p className="ml-3 text-sm font-medium text-green-900">
                                    {response.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        }

                        {
                          response.success === false &&
                          (
                            <div className='bg-red-50 rounded transition-all duration-500 mb-4 mt-4'>
                              <div className='p-3'>
                                <div className='flex items-start'>
                                  <svg className="flex-shrink-0 w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                  </svg>
                                  <p className="ml-3 text-sm font-medium text-red-700">
                                    {response.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </React.Fragment>
                    )
                  }


                  <div>
                    <div className="flex justify-center">
                      <HCaptcha
                        sitekey={SITE_KEY_TEST}
                        onVerify={setToken}
                        onError={onError}
                        onExpire={onExpire}
                        ref={captchaRef}
                      />
                    </div>
                    {
                      formErrors["captcha"] &&
                      (
                        <ul className="pl-4 mt-1 text-sm font-medium text-red-500 list-disc list-outside">
                          {
                            formErrors["captcha"]
                          }
                        </ul>
                      )
                    }
                  </div>

                  <div className="flex lg:justify-start justify-center">
                    <button type="submit" className="w-full inline-flex items-center justify-center w-[303px] px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500">
                      <svg aria-hidden="true" className={`${isBtnLoading === true ? '' : 'hidden'} mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      Submit
                    </button>
                  </div>

                </div>
              </form>

            </div>

            <div className="lg:col-span-5">

              <div className="grid grid-cols-1 mx-auto mt-8 gap-y-4 text-left md:grid-cols-2 md:gap-x-8 lg:mt-0">
                <div className="overflow-hidden bg-white shadow-md rounded-xl">
                  <div className="p-9">
                    <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19 3.50556V29.5056M19 3.50556C16.6642 1.95373 13.493 1 10 1C6.50702 1 3.33579 1.95373 1 3.50556V29.5056C3.33579 27.9537 6.50702 27 10 27C13.493 27 16.6642 27.9537 19 29.5056M19 3.50556C21.3358 1.95373 24.507 1 28 1C31.493 1 34.6642 1.95373 37 3.50556V29.5056C34.6642 27.9537 31.493 27 28 27C24.507 27 21.3358 27.9537 19 29.5056"
                        stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <h3 className="mt-8 text-xl font-bold text-gray-900 sm:mt-10 font-pj">
                      User guide
                    </h3>
                    <p className="mt-6 text-base text-gray-600 font-pj">
                      Need a hand getting started with VODIUM? Our extensive user guide contains everything you need to know
                      to begin.
                    </p>
                    <a href="https://vodium.us/user-guide" title="" className="inline-flex items-center mt-8 font-bold text-indigo-600 hover:text-indigo-800 font-pj">
                      View user guide
                      <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="overflow-hidden bg-white shadow-md rounded-xl">
                  <div className="p-9">
                    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M25 9L34.1056 4.44721C35.4354 3.78231 37 4.7493 37 6.23607V19.7639C37 21.2507 35.4354 22.2177 34.1056 21.5528L25 17M5 25H21C23.2091 25 25 23.2091 25 21V5C25 2.79086 23.2091 1 21 1H5C2.79086 1 1 2.79086 1 5V21C1 23.2091 2.79086 25 5 25Z"
                        stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <h3 className="mt-8 text-xl font-bold text-gray-900 sm:mt-10 font-pj">
                      How to use
                    </h3>
                    <p className="mt-6 text-base text-gray-600 font-pj">
                      Need tips and tricks on how to use VODIUM? Watch our 90 second tutorial video on how to use VODIUM to
                      look like a pro on camera.
                    </p>
                    <a href="https://youtu.be/rWynEatHfGg" title="" className="inline-flex items-center mt-8 font-bold text-indigo-600 hover:text-indigo-800 font-pj">
                      View video tutorial
                      <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Portal